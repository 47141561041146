<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="维修厂名称" prop="name">
							<el-input
								v-model="formData.name"
								placeholder="请输入维修厂名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="所属公司" prop="companyIds">
							<el-select
								v-model="formData.companyIds"
								filterable
								multiple
								placeholder="请选择所属公司"
								style="width: 100%"
							>
								<el-option
									v-for="(item, index) in companyOptions"
									:key="item.companyId"
									:label="item.companyName"
									:value="item.companyId"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="维修厂位置" prop="addressDesc">
							<el-input
								v-model="formData.addressDesc"
								placeholder="请输入维修厂位置"
								maxlength="50"
								clearable
								type="textarea"
								:autosize="{ minRows: 3 }"
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="联系人姓名" prop="contactsPerson">
							<el-input
								v-model="formData.contactsPerson"
								placeholder="请输入联系人姓名"
								maxlength="20"
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="联系人电话" prop="contactsPhone">
							<el-input
								v-model="formData.contactsPhone"
								placeholder="请输入联系人电话"
								maxlength="20"
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="合作状态" prop="status">
							<el-radio-group v-model="formData.status" :disabled="isDisabled">
								<el-radio v-for="(item, index) in statusOptions" :key="index" :label="item.code">
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button :loading="isLoading" type="primary" :disabled="isDisabled" @click="onSubmit">
					保 存
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref, defineEmits } from 'vue'
import { rules } from '../config'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const dialogTitle = ref('')

const formInitValue = {
	addressDesc: '',
	companyIds: [],
	contactsPerson: '',
	contactsPhone: '',
	name: '',
	status: '',
}

const formData = reactive({ ...formInitValue })
const formRule = rules
const companyOptions = ref([])
const statusOptions = [
	{ name: '启用', code: 1 },
	{ name: '禁用', code: 0 },
]

let formType = 'create'
let waitUpdateId = ''

function initEnums() {
	Request({
		url: 'suf4-user-service/company/select?status=1',
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('枚举获取成功 ==>', res.data)
				companyOptions.value = res.data
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	formType = 'create'
	dialogTitle.value = '新增维修厂'
	initEnums()
	isShowDialog.value = true
}

function update(item) {
	formType = 'update'
	dialogTitle.value = '编辑维修厂'
	waitUpdateId = item.id
	Object.assign(formData, item)
	formData.companyIds = item.companyList.map(item => item.companyId)
	initEnums()
	isShowDialog.value = true
}

function deleteItem() {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {})
		.catch(() => {})
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = formData
		if (formType === 'create') {
			requestUrl = `suf4-user-service/sysRepairStorage/create/10`
			delete params.id
		} else {
			requestUrl = `suf4-user-service/sysRepairStorage/update`
			params.id = waitUpdateId
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	Object.assign(formData, formInitValue)
}

defineExpose({ create, update, deleteItem })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
