import { phoneReg } from '@/utils/regUtil'

export const columns = [
	{
		prop: 'name',
		label: '维修厂名称',
	},
	{
		prop: 'companyList',
		label: '所属公司',
		formatter: function (row, column, cellValue, index) {
			if (!cellValue || cellValue.length === 0) {
				return '无'
			}
			return cellValue.map(item => item.companyName).join(',')
		},
	},
	{
		prop: 'addressDesc',
		label: '修理厂位置',
	},
	{
		prop: 'contactsPerson',
		label: '联系人',
	},
	{
		prop: 'contactsPhone',
		label: '联系电话',
	},
	{
		prop: 'status',
		label: '合作状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 1 ? '启用' : '禁用'
		},
	},
	/* {
		prop: 'rolePermissions',
		label: '联系人',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '个人' : '公司'
		},
	}, */
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '维修厂名称',
			attr: 'name',
			type: 'search',
			placeholder: '请输入维修厂名称',
			clearable: true,
		},
		{
			attr: 'companyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '选择所属公司',
		},
		{
			attr: 'status',
			label: '合作状态',
			type: 'select',
			placeholder: '请选择合作状态',
			options: [
				{ name: '启用', code: 1 },
				{ name: '禁用', code: 0 },
			],
		},
	],
}

export const rules = {
	addressDesc: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
	name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
	status: [{ required: true, message: '请选择合作状态', trigger: 'blur' }],
	contactsPhone: [
		{ required: true, message: '请输入联系人', trigger: 'blur' },
		// { pattern: phoneReg, message: '请输入正确的电话号码', trigger: 'blur' },
	],
	contactsPerson: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
	companyIds: [{ required: true, message: '请选择合作公司', trigger: 'blur' }],
}
